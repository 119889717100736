import React from 'react';
import './App.css';


function App() {
    return (
        <div className="App">
            <p>押すボタンによって、メッセージの初期値が変わります。</p>
            <a href="https://line.me/R/oaMessage/@414mxxjp/%E8%85%B0%E7%97%9B%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%8B%E3%82%89%E3%81%AE%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%0D%0A%0D%0A%E3%81%94%E4%BA%88%E7%B4%84%E5%86%85%E5%AE%B9%E3%81%AE%E6%96%B9%E3%81%AF%E4%B8%8B%E8%A8%98%E3%81%AE%E9%A0%85%E7%9B%AE%E3%81%AE%E9%80%81%E4%BF%A1%E3%81%8A%E9%A1%98%E3%81%84%E3%81%97%E3%81%BE%E3%81%99%E3%80%82%0D%0A%0D%0A%E2%97%8E%E3%81%8A%E5%90%8D%E5%89%8D%E3%83%95%E3%83%AB%E3%83%8D%E3%83%BC%E3%83%A0%0D%0A%0D%0A%0D%0A%E2%97%8E%E3%81%8A%E5%9B%B0%E3%82%8A%E3%81%AE%E7%97%87%E7%8A%B6%0D%0A%0D%0A%0D%0A%E2%97%8E%E3%81%94%E5%B8%8C%E6%9C%9B%E3%81%AE%E3%81%8A%E6%97%A5%E3%81%AB%E3%81%A1%0D%0A">腰痛ページの<br/>LINE友達追加</a>
            <a href="https://line.me/R/oaMessage/@414mxxjp/%E5%A4%96%E5%8F%8D%E6%AF%8D%E8%B6%BE%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%8B%E3%82%89%E3%81%AE%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%0D%0A%0D%0A%E3%81%94%E4%BA%88%E7%B4%84%E5%86%85%E5%AE%B9%E3%81%AE%E6%96%B9%E3%81%AF%E4%B8%8B%E8%A8%98%E3%81%AE%E9%A0%85%E7%9B%AE%E3%81%AE%E9%80%81%E4%BF%A1%E3%81%8A%E9%A1%98%E3%81%84%E3%81%97%E3%81%BE%E3%81%99%E3%80%82%0D%0A%0D%0A%E2%97%8E%E3%81%8A%E5%90%8D%E5%89%8D%E3%83%95%E3%83%AB%E3%83%8D%E3%83%BC%E3%83%A0%0D%0A%0D%0A%0D%0A%E2%97%8E%E3%81%8A%E5%9B%B0%E3%82%8A%E3%81%AE%E7%97%87%E7%8A%B6%0D%0A%0D%0A%0D%0A%E2%97%8E%E3%81%94%E5%B8%8C%E6%9C%9B%E3%81%AE%E3%81%8A%E6%97%A5%E3%81%AB%E3%81%A1%0D%0A">外反母趾ページの<br/>LINE友達追加</a>
        </div>
    );
}

export default App;

